import { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  getDate,
  getDay,
  getWeeksInMonth,
  isPast,
  startOfMonth
} from 'date-fns'

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

import TopTableDays from './Components/TopTableDays'
import CardDay from './Components/CardDay'

import { formatPrice } from 'utils/format'

import Tooltip from 'components/Tooltip'
import { DefaultRootState, useDispatch, useSelector } from 'react-redux'
import {
  addDisabledDay,
  setDisabledDays
} from 'store/modules/calendarGoal/actions'
import { deepClone } from 'utils/deepClone'
import { IDwt, ICurrentGoalProps } from '../..'
import { IDays, IUserWorking } from 'store/modules/goal/types'
import { ICalendarGoalProps } from 'store/modules/calendarGoal/types'

import { Container } from './style'

interface SelectorCalendarGoal {
  state: DefaultRootState
  calendarGoal: ICalendarGoalProps
}

export interface IEditableDay extends Omit<IDays, 'users'> {
  users: IUserWorking[]
}

interface CalendarProps {
  data: ICurrentGoalProps
  dwt: IDwt
  setDwt: (dwt: IDwt) => void
  days: IDays[]
  setDays: (days: IDays[]) => void
  weekStartIndex: number
  month: Date
  mainGoalValue: number
  setTotalDaysValue: (daysValue: number) => void
  updateGoal: (obj: any) => void
  suggestion: boolean
  isCalendarLocked: boolean
  usersWorking: IUserWorking[]
}

const Calendar = ({
  data,
  dwt,
  setDwt,
  days,
  setDays,
  weekStartIndex,
  month,
  setTotalDaysValue,
  mainGoalValue,
  updateGoal,
  suggestion,
  isCalendarLocked,
  usersWorking
}: CalendarProps) => {
  const { disabledDays, days: initialDaysValue } = useSelector<
    SelectorCalendarGoal,
    ICalendarGoalProps
  >(store => store.calendarGoal)

  const dispatch = useDispatch()
  const [editableDays, setEditableDays] = useState<IDays[]>([])
  const [daysIndexWithFixedGoal, setDaysIndexWithFixedGoal] = useState<
    number[]
  >(() => {
    if (days.length > 0) {
      const lockedDaysIndex: number[] = []

      days.forEach((day, index) => {
        if (day.goalLocked) {
          lockedDaysIndex.push(index)
        }
      })

      return lockedDaysIndex
    }
    return []
  })

  const [isEditing, setIsEditing] = useState(-1)
  const [progressBar, setProgressBar] = useState(0)

  const nextInputRef = useRef<HTMLInputElement | null>(null)
  let newDisabledDays = [...disabledDays]

  useEffect(() => {
    setProgressBar(dwt.total / mainGoalValue)
    setTotalDaysValue(Number(dwt.total.toFixed(2)))
  }, [dwt.total])

  const generateDwt = (days: IDays[]) => {
    setDwt(
      days.reduce(
        (acc, day, index) => {
          const weekStartIndex = getDay(startOfMonth(new Date(data.month)))
          const i = weekStartIndex + (getDate(new Date(day.date)) - 1)
          const weekIndex = Math.floor(i / 7)
          acc.weeks[weekIndex] += day.goal
          acc.days[i - weekIndex * 7] += day.goal
          if (!newDisabledDays.includes(index)) {
            acc.total += day.goal
          }
          return acc
        },
        {
          weeks: new Array(getWeeksInMonth(new Date(data.month))).fill(0),
          days: [0, 0, 0, 0, 0, 0, 0],
          total: 0
        }
      )
    )
  }

  useEffect(() => {
    if (!(editableDays?.length > 0) && days.length > 0) {
      setEditableDays(deepClone(days))
    }
  }, [editableDays?.length, days])

  const newDate = new Date()
  const currentMonth = newDate.getMonth()
  const currentYear = newDate.getFullYear()

  const calendarMonth = month.getMonth()
  const calendarYear = month.getFullYear()
  const daysInCalendarMonth = new Date(
    calendarYear,
    calendarMonth + 1,
    0
  ).getDate()

  const today =
    calendarYear > currentYear
      ? 0
      : currentMonth === calendarMonth
      ? newDate.getDate()
      : currentMonth > calendarMonth
      ? daysInCalendarMonth
      : 0

  const oldDays: IDays[] = deepClone(days)

  const setWorkDay = (i: number) => (value: boolean) => {
    if (suggestion) {
      if (!value) {
        dispatch(addDisabledDay(i))
        newDisabledDays.push(i)
      } else if (newDisabledDays.includes(i)) {
        const dayIndex = newDisabledDays.indexOf(i)
        newDisabledDays.splice(dayIndex, 1)
        dispatch(setDisabledDays(newDisabledDays))
      }

      const newDaysIndexWithFixedGoal = [...daysIndexWithFixedGoal]

      if (newDaysIndexWithFixedGoal.includes(i)) {
        const dayIndex = newDaysIndexWithFixedGoal.indexOf(i)
        newDaysIndexWithFixedGoal.splice(dayIndex, 1)
        setDaysIndexWithFixedGoal(newDaysIndexWithFixedGoal)
      }

      const isPreviousDay = isPast(
        new Date(oldDays[i].date).setUTCHours(0, 0, 0, 0)
      )

      const disabledDaysWorking: number[] = []
      oldDays.forEach((day, index) => {
        // verifica se o dia nao esta sendo trabalhado
        // depois compara se o dia atual eh diferente do dia clicado
        // caso seja igual o dia clicado ele verifica se esta sendo ativado ou desativado
        if (!day.working && (index !== i || !value)) {
          disabledDaysWorking.push(index)
        } else if (day.working && index === i && !value) {
          disabledDaysWorking.push(index)
        }
      })

      // verifica se esta alterando um calendario com dias previamente desablitados
      if (disabledDaysWorking.length && !disabledDays.length) {
        dispatch(setDisabledDays(disabledDaysWorking))
        newDisabledDays = disabledDaysWorking
      }

      const weights = oldDays.reduce(
        (acc, day, index) => {
          if (!newDaysIndexWithFixedGoal.includes(index)) {
            acc.totalWeight += data.daysWeightSuggestion[index]
            if (newDisabledDays.includes(index)) {
              acc.notSelectedDaysWeight += data.daysWeightSuggestion[index]
            } else if (
              (day.working || value) &&
              !(index > (isCalendarLocked ? today - 1 : -1))
            ) {
              acc.disabledDaysWeight += data.daysWeightSuggestion[index]
              acc.disabledDaysValue += initialDaysValue[index].goal
            }
          }
          return acc
        },
        {
          notSelectedDaysWeight: 0,
          disabledDaysWeight: 0,
          disabledDaysValue: 0,
          totalWeight: 0
        }
      )

      const fixedDaysGoalValue = newDaysIndexWithFixedGoal.reduce(
        (acc, curr) => {
          return (acc += days[curr].goal)
        },
        0
      )

      const mainGoalValueWithoutFixedDaysGoal =
        mainGoalValue - fixedDaysGoalValue

      const calculateNewGoal = (index: number) => {
        return (
          ((data.daysWeightSuggestion[index] +
            (data.daysWeightSuggestion[index] * weights.notSelectedDaysWeight) /
              (weights.totalWeight -
                weights.disabledDaysWeight -
                weights.notSelectedDaysWeight)) *
            (mainGoalValueWithoutFixedDaysGoal - weights.disabledDaysValue)) /
          (weights.totalWeight - weights.disabledDaysWeight)
        )
      }

      const newDaysList: IDays[] = oldDays.map((day, index) => {
        if (
          day.working &&
          index !== i &&
          !newDisabledDays.includes(index) &&
          !newDaysIndexWithFixedGoal.includes(index) &&
          index > (isCalendarLocked ? today - 1 : -1)
        ) {
          return {
            ...day,
            goal: calculateNewGoal(index)
          }
        } else if (index === i) {
          let goal = 0

          if (isPreviousDay && isCalendarLocked) {
            goal = value ? initialDaysValue[i].goal : 0
          } else if (value) {
            goal = calculateNewGoal(index)
          }
          if (day.goalLocked) {
            delete day.goalLocked
          }
          return {
            ...day,
            working: value,
            goal
          }
        } else if (newDaysIndexWithFixedGoal.includes(index)) {
          return day
        } else if (index > (isCalendarLocked ? today - 1 : -1)) {
          return {
            ...day,
            goal: 0
          }
        }
        return day
      })

      setDays(newDaysList)
      setEditableDays(newDaysList)
      generateDwt(newDaysList)
      updateGoal({ lastDaysBackup: newDaysList })
    } else {
      oldDays[i].working = value
      oldDays[i].goal = value
        ? Number((mainGoalValue / days.length).toFixed(2))
        : 0

      setDays(oldDays)
      setEditableDays(oldDays)
      generateDwt(oldDays)
    }
  }

  const handleEditUsersWorking = () => {
    setDays(editableDays)
    updateGoal({ lastDaysBackup: editableDays })
  }

  const setEditableUsersWorkingOnDay =
    (i: number) => (sellers: IUserWorking[]) => {
      const newDays: IDays[] = editableDays.map((day, index) => {
        if (index === i) {
          const activeSellers = sellers.filter(seller => seller.active)
          const activeSellersIds = activeSellers.map(user => user.userId?._id)
          return {
            ...day,
            users: activeSellersIds
          }
        }
        return day
      })

      setEditableDays(newDays)
    }

  const setValue = (i: number) => (e: { target: { inputValue: number } }) => {
    //Não recalcular se valor não mudar
    const oldDays: IDays[] = deepClone(days)
    const oldGoalValue = oldDays[i].goal
    const inputGoalValue = e.target.inputValue ?? 0

    if (inputGoalValue !== oldGoalValue) {
      const newDaysIndexWithFixedGoal = daysIndexWithFixedGoal.includes(i)
        ? daysIndexWithFixedGoal
        : [...daysIndexWithFixedGoal, i]

      if (suggestion) {
        const disabledDaysWorking: number[] = []
        oldDays.forEach((day, index) => {
          // verifica se o dia nao esta sendo trabalhado
          if (!day.working) {
            disabledDaysWorking.push(index)
          }
        })

        // verifica se esta alterando um calendario com dias previamente desablitados
        if (disabledDaysWorking.length && !disabledDays.length) {
          dispatch(setDisabledDays(disabledDaysWorking))
          newDisabledDays = disabledDaysWorking
        }

        const weights = oldDays.reduce(
          (acc, day, index) => {
            if (!newDaysIndexWithFixedGoal.includes(index)) {
              acc.totalWeight += data.daysWeightSuggestion[index]
              if (newDisabledDays.includes(index)) {
                acc.notSelectedDaysWeight += data.daysWeightSuggestion[index]
              } else if (
                day.working &&
                !(index > (isCalendarLocked ? today - 1 : -1))
              ) {
                acc.disabledDaysWeight += data.daysWeightSuggestion[index]
                acc.disabledDaysValue += initialDaysValue[index].goal
              }
            }
            return acc
          },
          {
            notSelectedDaysWeight: 0,
            disabledDaysWeight: 0,
            disabledDaysValue: 0,
            totalWeight: 0
          }
        )

        const fixedDaysGoalValue = newDaysIndexWithFixedGoal.reduce(
          (acc, curr) => {
            return curr === i
              ? (acc += inputGoalValue)
              : (acc += oldDays[curr].goal)
          },
          0
        )
        const mainGoalValueWithoutFixedDaysGoal =
          mainGoalValue - fixedDaysGoalValue

        const calculateNewGoal = (index: number) => {
          return (
            ((data.daysWeightSuggestion[index] +
              (data.daysWeightSuggestion[index] *
                weights.notSelectedDaysWeight) /
                (weights.totalWeight -
                  weights.disabledDaysWeight -
                  weights.notSelectedDaysWeight)) *
              (mainGoalValueWithoutFixedDaysGoal - weights.disabledDaysValue)) /
            (weights.totalWeight - weights.disabledDaysWeight)
          )
        }

        const newDaysList: IDays[] = oldDays.map((day, index) => {
          if (
            day.working &&
            index !== i &&
            !newDisabledDays.includes(index) &&
            !newDaysIndexWithFixedGoal.includes(index) &&
            index > (isCalendarLocked ? today - 1 : -1)
          ) {
            return {
              ...day,
              goal: calculateNewGoal(index)
            }
          } else if (index === i) {
            return {
              ...day,
              goal: inputGoalValue,
              ...(newDaysIndexWithFixedGoal.includes(i) && { goalLocked: true })
            }
          } else if (newDaysIndexWithFixedGoal.includes(index)) {
            return {
              ...day,
              goal: oldDays[index].goal,
              goalLocked: true
            }
          } else if (index > (isCalendarLocked ? today - 1 : -1)) {
            return {
              ...day,
              goal: 0
            }
          }
          return day
        })

        setDays(newDaysList)
        setEditableDays(newDaysList)
        generateDwt(newDaysList)
        if (!daysIndexWithFixedGoal.includes(i)) {
          setDaysIndexWithFixedGoal(prev => [...prev, i])
        }
      } else {
        oldDays[i].goal = inputGoalValue

        const dwtWeek = [...dwt.weeks]
        const dwtDays = [...dwt.days]

        const weekIndex = Math.floor((i + weekStartIndex) / 7)

        dwtWeek[weekIndex] += inputGoalValue - oldGoalValue
        dwtDays[i + weekStartIndex - weekIndex * 7] +=
          inputGoalValue - oldGoalValue

        setDwt({
          ...dwt,
          weeks: dwtWeek,
          days: dwtDays,
          total: dwt.total + inputGoalValue - oldGoalValue
        })
        setEditableDays(oldDays)
        setDays(oldDays)
      }
    }
  }

  const getDayOutMonth = (day: number) => {
    const dayMS = 24 * 60 * 60 * 1000 * day
    const date = new Date(month)
    date.setTime(date.getTime() - dayMS)

    return date
  }

  const changeDayUsersType = (
    day: IDays | IEditableDay // Precisa tratar dayUsers pois ele pode ser composto de string por causa do back e precisa ser obj
  ): IEditableDay => {
    // (=== string) pro caso de ser apenas o id, e (!length) pra caso nao tenha usuarios aquele dia
    return typeof (day?.users[0] === 'string' || !day?.users.length)
      ? {
          ...day,
          users: [...data.usersWorking].map(userW => ({
            ...userW,
            active: [...day.users].includes(userW.userId?._id)
          }))
        }
      : (day as IEditableDay)
  }

  return (
    <Container>
      <div className='calendar'>
        <div style={{ display: 'flex' }}>
          <div>
            <TopTableDays
              topTable={[
                'Domingo',
                'Segunda-feira',
                'Terça-feira',
                'Quarta-feira',
                'Quinta-feira',
                'Sexta-feira',
                'Sábado'
              ]}
            />
            <div className='calendar-month' data-clarity-unmask='true'>
              {days.length > 0 &&
                new Array(7 * getWeeksInMonth(month)).fill(null).map((_, i) => {
                  const monthIndex = i - weekStartIndex
                  // Índice do próximo dia util é o dia util mais próximo do dia que está sendo editado
                  // Primeiro verifica-se se está em modo de edição, então é feito um splice pra economizar
                  // o loop e busca-se o primeiro dia util, onde ao adicionar o indice do dia sendo editado é igual ao indice no mês
                  const nextWorkingDayIndex =
                    isEditing > 0
                      ? isEditing +
                        days
                          .slice(isEditing - weekStartIndex + 1)
                          .findIndex(day => day.working) +
                        1
                      : 1

                  if (i < weekStartIndex || monthIndex >= days.length) {
                    const date = getDayOutMonth(weekStartIndex - i)
                    return (
                      <CardDay
                        data={data}
                        key={i}
                        setWorkDay={setWorkDay(monthIndex)}
                        date={date}
                        index={i}
                        disabled
                      />
                    )
                  } else {
                    return (
                      <CardDay
                        ref={
                          nextWorkingDayIndex === i ? nextInputRef : undefined
                        }
                        data={data}
                        day={changeDayUsersType(days[monthIndex])}
                        date={new Date(days[monthIndex].date)}
                        key={i}
                        setWorkDay={setWorkDay(monthIndex)}
                        setValue={setValue(monthIndex)}
                        handleNext={() => {
                          //Mandar para o próximo dia útil
                          if (nextWorkingDayIndex > isEditing) {
                            nextInputRef.current?.focus()
                            setIsEditing(nextWorkingDayIndex)
                          } else {
                            setIsEditing(-1)
                          }
                        }}
                        locked={
                          suggestion &&
                          isCalendarLocked &&
                          i < today + weekStartIndex
                        }
                        index={i}
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                        suggestion={suggestion}
                        usersWorking={usersWorking}
                        updateGoal={updateGoal}
                        editableDay={
                          editableDays.length > 0
                            ? changeDayUsersType(editableDays[monthIndex])
                            : undefined
                        }
                        setEditableUsersOnDays={setEditableUsersWorkingOnDay(
                          monthIndex
                        )}
                        editUsersWorking={handleEditUsersWorking}
                      />
                    )
                  }
                })}
            </div>
          </div>
          <div className='calendar-total'>
            <TopTableDays topTable={['Total']} />
            <div className='weeks-cards'>
              {dwt.weeks?.map((week, i) => (
                <div key={i} className='total-card'>
                  <span>{formatPrice(week)}</span>
                  <h4>{Math.ceil((week * 100) / mainGoalValue)}%</h4>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '70vw' }} className='calendar-total'>
            <div className='days-cards'>
              {dwt.days?.map((day, i) => (
                <div key={i} className='total-card'>
                  <span>{formatPrice(day)}</span>
                  <h4>{Math.ceil((day * 100) / mainGoalValue)}%</h4>
                </div>
              ))}
            </div>
          </div>
          <div
            style={{ ...(isEditing > 0 && { zIndex: 5 }) }}
            className='calendar-total'
          >
            <div className='total'>
              <div className='total-card'>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span>Soma dos dias</span>
                  <span data-tip data-for='tooltip-sum-days'>
                    {Number((dwt.total || 0).toFixed(2)) !==
                      Number((mainGoalValue || 0).toFixed(2)) && (
                      <ErrorOutlineIcon
                        fontSize='small'
                        style={{ color: '#9E9E9E', marginLeft: 5 }}
                      />
                    )}
                  </span>
                  <Tooltip
                    place='top'
                    content={
                      <p>
                        Iguale a soma dos dias com {'\n'}a meta principal da
                        loja ({formatPrice(mainGoalValue)})
                      </p>
                    }
                    id='tooltip-sum-days'
                  />
                </div>
                <span
                  style={{
                    color:
                      Number((dwt.total || 0).toFixed(2)) !==
                      Number((mainGoalValue || 0).toFixed(2))
                        ? 'red'
                        : '#27AE60',
                    fontSize: 14
                  }}
                >
                  {formatPrice(dwt.total)}
                </span>
                <div className='total-progress-container'>
                  <div className='bar'>
                    <div
                      className='progress'
                      style={{ width: `${progressBar * 100}%` }}
                    />
                  </div>
                </div>
                {Number(dwt.total.toFixed(2)) -
                  Number(mainGoalValue.toFixed(2)) <
                0 ? (
                  <span style={{ marginLeft: 'auto' }}>
                    Faltam{' '}
                    {formatPrice(
                      Number(
                        Number(mainGoalValue.toFixed(2)) -
                          Number(dwt.total.toFixed(2))
                      )
                    )}
                  </span>
                ) : (
                  Number((dwt.total || 0).toFixed(2)) !==
                    Number((mainGoalValue || 0).toFixed(2)) && (
                    <span style={{ marginLeft: 'auto' }}>
                      Retire{' '}
                      {formatPrice(
                        Number(dwt.total.toFixed(2)) -
                          Number(mainGoalValue.toFixed(2))
                      )}
                    </span>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

Calendar.propTypes = {
  days: PropTypes.array,
  setDays: PropTypes.func,
  weekStartIndex: PropTypes.number
}

export default Calendar
