import React, { useState, useEffect } from 'react'

// Libs
import { makeStyles } from '@material-ui/styles'
import { useSelector, useDispatch } from 'react-redux'
import { useConfirm } from 'material-ui-confirm'
import { useHistory } from 'react-router-dom'
import JwtDecode from 'jwt-decode'

// Components
import UsersTable from './components/UsersTable'
import AddUserModal from './components/AddUserModal'
import { Toolbar } from '../../components'
import EditUserModal from './components/EditUserModal'

// Actions
import {
  userListRequest,
  userAddRequest,
  userEditRequest,
  unLinkUser,
  userUpdateLink,
  userExistsRequest,
  userLinkRequest,
  userAddModal,
  userExistsSuccess,
  clearNewUser
} from '../../store/modules/user/actions'
import { requestFailed } from '../../store/modules/error/action'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}))

export default function UserList({ match }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const confirm = useConfirm()
  const history = useHistory()

  // userList: state.repositories.userList,
  // loading: state.repositories.loading

  // User
  const {
    userList,
    metadata,
    loading,
    searchLoading,
    userExists,
    addUserModal,
    error,
    user: loggedUser,
    newUser
  } = useSelector(state => state.user)

  // Goal
  const { currentGoal } = useSelector(state => state.goal)

  // Store
  const { store } = useSelector(state => state.store)

  const { storeId } = match.params
  const token = window.localStorage.getItem('@NeoPro:token')
  let decoded = {
    id: ''
  }

  if (token) {
    decoded = JwtDecode(token)
  }

  const [addModal, setAddModal] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [user, setUser] = useState()
  const [isLoading, setIsLoading] = useState(true)

  const currentUserStoreType = loggedUser.stores.find(
    store => store.storeId._id === storeId
  )?.type

  const onRefresh = () => {
    dispatch(userListRequest(storeId, token))
  }

  const handleChangePage = page => {
    if (page + 1 <= metadata.pagination.pageCount) {
      dispatch(
        userListRequest(storeId, token, page + 1, metadata.pagination.limit)
      )
    }
  }

  const handleChangeRows = rowsNumber => {
    dispatch(
      userListRequest(
        storeId,
        token,
        metadata.pagination.currentPage,
        Number(rowsNumber.props.value)
      )
    )
  }

  const handleSubmit = data => {
    dispatch(userEditRequest(data, token, user._id))
  }

  const handleOpenModal = data => {
    setUser(data)
    setEditModal(true)
  }

  const handleUpdateUserLink = data => {
    dispatch(
      userUpdateLink(
        data,
        user._id,
        localStorage.getItem('@NeoPro:token'),
        storeId
      )
    )
  }

  const handleLinkUser = data => {
    dispatch(
      userLinkRequest({ ...data, storeId: storeId }, userExists._id, token)
    )
  }

  const handleCheckCpf = data => {
    dispatch(userExistsRequest(data, token))
  }

  useEffect(() => {
    const rolesWithPermissionToAccessPage = [
      'owner',
      'director',
      'manager',
      'supervisor'
    ]

    if (loggedUser._id) {
      if (!rolesWithPermissionToAccessPage.includes(currentUserStoreType)) {
        history.push(`/${storeId}/dashboard`)
      }
      setIsLoading(false)
    }
  }, [storeId, loggedUser, history, currentUserStoreType])

  useEffect(() => {
    if (metadata.pagination.totalCount < 0) {
      onRefresh()
    }
  }, [])

  useEffect(() => {
    if (userList) {
      setAddModal(false)
      setEditModal(false)
    }
  }, [userList])

  useEffect(() => {
    if (!addUserModal) {
      dispatch(requestFailed(null))
      dispatch(
        userExistsSuccess({
          found: false
        })
      )
    }
  }, [addUserModal])

  useEffect(() => {
    if (newUser.success && newUser.type === 'seller' && currentGoal) {
      confirm({
        title: 'Vincular usuário a meta',
        description: `Foi identificada uma meta esse mês, deseja incluir o vendedor?`,
        confirmationText: 'Sim',
        cancellationText: 'Cancelar'
      })
        .then(() => {
          history.push(`/${storeId}/goals/${currentGoal._id}/${newUser.userId}`)
          dispatch(clearNewUser())
        })
        .catch(() => dispatch(clearNewUser()))
    }
  }, [newUser])

  const availableRoles = {
    supervisor: {
      communicator: 'Comunicador',
      manager: 'Gerente',
      cashier: 'Caixa',
      seller: 'Vendedor'
    },
    manager: {
      communicator: 'Comunicador',
      manager: 'Gerente',
      cashier: 'Caixa',
      seller: 'Vendedor'
    },
    director: {
      communicator: 'Comunicador',
      manager: 'Gerente',
      cashier: 'Caixa',
      seller: 'Vendedor',
      supervisor: 'Supervisor'
    },
    owner: {
      communicator: 'Comunicador',
      owner: 'Dono',
      manager: 'Gerente',
      cashier: 'Caixa',
      seller: 'Vendedor',
      supervisor: 'Supervisor'
    }
  }

  return (
    <div className={classes.root}>
      <Toolbar
        onAdd={() => dispatch(userAddModal(true))}
        onRefresh={onRefresh}
        actionText='Adicionar Usuário'
      />
      {!isLoading && (
        <UsersTable
          users={userList}
          usersWorking={
            currentGoal && currentGoal.usersWorking.map(u => u.userId?._id)
          }
          handleOpenModal={handleOpenModal}
          onChangeRows={handleChangeRows}
          onChangePage={handleChangePage}
          metadata={metadata}
          currentUserStoreType={currentUserStoreType}
          availableRoles={availableRoles}
        />
      )}
      <EditUserModal
        active={editModal}
        onClose={() => setEditModal(false)}
        loading={loading}
        user={user}
        storeId={storeId}
        isDifferentUser={user && decoded.id !== user._id}
        handleUpdateUserLink={handleUpdateUserLink}
        handleSubmitData={handleSubmit}
        handleUnLinkUser={() => {
          dispatch(unLinkUser(storeId, user._id, token))
          //atualizar a lista (puxar um novo usuário para lista)
          onRefresh()
        }}
        currentUserStoreType={currentUserStoreType}
        availableRoles={availableRoles}
      />
      <AddUserModal
        handleCheckCpf={handleCheckCpf}
        userExists={userExists}
        error={error}
        loading={loading}
        active={addUserModal}
        searchLoading={searchLoading}
        currentGoal={currentGoal}
        onClose={() => dispatch(userAddModal(false))}
        linkUser={data => handleLinkUser(data)}
        addUser={user =>
          dispatch(
            userAddRequest(
              { ...user, storeId, companyId: store.companyId },
              token
            )
          )
        }
        currentUserStoreType={currentUserStoreType}
        availableRoles={availableRoles}
      />
    </div>
  )
}
