import styled from 'styled-components'
import palette from 'theme/palette'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 400px;
  background-color: white;
  border-radius: 10px;
  padding: 50px 30px;
  -webkit-box-shadow: 0px 6px 14px 1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 6px 14px 1px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 6px 14px 1px rgba(0, 0, 0, 0.15);
  font-family: 'Roboto', sans-serif;

  .top-content {
    padding: 15px 0;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 10px;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background: #fafafa;
      transform: translate(-50%, -50%);
      -webkit-box-shadow: 0px 6px 14px 1px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0px 6px 14px 1px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 6px 14px 1px rgba(0, 0, 0, 0.1);

      :first-child {
        font-size: 45px;
      }
    }

    .alert {
      color: #e64c3d;
    }

    .close-modal-top-button {
      position: absolute;
      top: 0;
      right: 0;
      margin: 10px;
      color: #cacaca;
      cursor: pointer;
    }
  }

  .title-header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 0 30px 15px;
    font-family: 'Roboto', sans-serif;

    h1 {
      font-size: 18px;
      margin: 0;
      color: #263238;
      font-weight: bold;
      margin-bottom: 10px;
    }

    p {
      color: #263238;
      font-size: 14px;
      margin-bottom: 0;
    }
  }

  .center-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: 20px auto;
    font-family: 'Roboto', sans-serif;

    .close-modal-top-button {
      position: absolute;
      top: 0;
      right: 0;
      margin: 10px;
      color: #c4c4c4;
      cursor: pointer;
    }

    .copy-store-container {
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .copy-store-name {
        background-color: #e8e5eb;
        padding: 10px;
        border-radius: 5px;
        margin-bottom: 20px;
        color: ${palette.primary.main};
        font-size: 14px;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      .copy-store-form {
        padding: 0 20px;

        span {
          margin-left: 10px;
        }
      }
    }

    h3 {
      margin: 0;
    }
  }

  .footer-buttons {
    display: flex;
    width: 100%;
    position: absolute;
    bottom: 0;
    margin-top: 10px;
    border-radius: 0px 0px 10px 10px;
    overflow: hidden;
    font-family: 'Roboto', sans-serif;

    .alert {
      background-color: #e64c3d !important;
    }

    .action-button {
      background-color: ${palette.primary.main};
      color: white;
      transition: 0.3s ease-in-out;
    }

    .action-button-left {
      color: ${palette.primary.main};
    }

    button {
      height: 50px;
      border: 0;
      cursor: pointer;
      transition: filter 0.2s;
      outline: none;
      font-size: 12px;
      color: #263238;
      width: 100%;
      font-weight: 600;
      font-family: 'Roboto', sans-serif;

      &:disabled {
        cursor: not-allowed;
        background-color: #a0a0a0;
      }

      &:hover {
        filter: brightness(0.95);
      }
    }
  }
`
