// React
import { useState, useEffect } from 'react'
// Redux
import { useSelector, useDispatch, DefaultRootState } from 'react-redux'

// Libs
import DayPicker, { DateUtils } from 'react-day-picker'
import moment from 'moment'

// Styles

// Utils

// Components
import {
  InputContainer,
  SliderContainer,
  WeightSelection,
  WeightSelectionItem
} from 'components/UsersGoal/components/GoalSliderModal/styles'
import Tooltip from 'components/Tooltip'

// MaterialUI
import {
  Grid,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Typography
} from '@material-ui/core'
import { useConfirm } from 'material-ui-confirm'
import { makeStyles } from '@material-ui/core/styles'
import {
  Lock,
  CheckCircle,
  RadioButtonUnchecked,
  ErrorOutline as ErrorOutlineIcon,
  Speed as SpeedIcon
} from '@material-ui/icons'
import PersonRemoveIcon from '@mui/icons-material/PersonRemove'
import { Checkbox, Slider, CircularProgress } from '@mui/material'
import { inputListRequest } from '../../../../store/modules/input/actions'
import { monthRequest } from '../../../../store/modules/report/actions'
import { formatPrice } from '../../../../utils/format'
import {
  ActionButtons,
  Container,
  DSRCheckbox,
  GoalCard,
  Item,
  ItemTitle,
  Picker
} from './styles'
import dayPickerBR from '../../../../common/DayPicker-pt-BR'
import {
  IDays,
  IGoal,
  IMonthGoal,
  IUserWorking
} from 'store/modules/goal/types'
import { IInput, IInputProps } from 'store/modules/input/types'
import { IMonthReport, IReportProps } from 'store/modules/report/types'
import { BootstrapTooltip } from 'components/BootstrapTooltip'

const useStyles = makeStyles(theme => ({
  root: { flexGrow: 1 },
  margin: { margin: theme.spacing(1) },
  buttonSuccess: {
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white'
    }
  }
}))

interface SelectorInput {
  state: DefaultRootState
  input: IInputProps
}

interface SelectorReport {
  state: DefaultRootState
  report: IReportProps
}

interface IDaysWithWeight extends IDays {
  dayWeight: number
}

interface SellerProps {
  dsr: boolean
  goalWeight: number | string
  weightLocked: boolean
  edited: boolean
}

interface IUwwSellerGoalProps {
  remainingGoal: number
  sold: number
  lastInput: string
  accGoalAfterExit?: number
}

interface EditUserGoalModalProps {
  modal: boolean
  currentMonthGoal: IGoal
  usersWorkingLength: number
  handleRemoveUser: (user: IUserWorking) => void
  user: IUserWorking | undefined
  onClose: () => void
  handleUpdateUserWeight: (
    days: string[],
    user: IUserWorking,
    tab: 'fixed' | 'auto',
    sellerWeight: number,
    goalId: string,
    isRemoving: boolean
  ) => void
  loading: boolean
}

export default function EditUserGoalModal({
  modal,
  currentMonthGoal,
  usersWorkingLength,
  handleRemoveUser,
  user,
  onClose,
  handleUpdateUserWeight,
  loading
}: EditUserGoalModalProps) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const confirm = useConfirm()
  const token = window.localStorage.getItem('@NeoPro:token')

  const { monthReport, reportsList } = useSelector<
    SelectorReport,
    IReportProps
  >(state => state.report)
  const { inputList, metadata: inputMetadata } = useSelector<
    SelectorInput,
    IInputProps
  >(state => state.input)

  const [days, setDays] = useState<Date[]>([])
  const [seller, setSeller] = useState<SellerProps>({
    dsr: true,
    goalWeight: 100,
    weightLocked: false,
    edited: false
  })

  const [tab, setTab] = useState(0)
  const [reports, setReports] = useState<IMonthReport | null>(null)
  const [inputsList, setInputsList] = useState<IInput[]>([])
  const tabColorOptions = tab === 0 ? '#FFC700' : '#263238'

  const [accGoal, setAccGoal] = useState<IMonthGoal[] | number>()

  // Ao inicializar, se não tiver exibindo nenhum report, solicita os do mês
  useEffect(() => {
    if (inputMetadata.pagination.totalCount > 0) {
      if (
        !monthReport &&
        !reportsList &&
        !reports &&
        currentMonthGoal.storeId
      ) {
        dispatch(monthRequest(currentMonthGoal.storeId, token))
      }
    } else if (
      inputMetadata.pagination.totalCount < 0 &&
      currentMonthGoal.storeId
    ) {
      dispatch(inputListRequest(currentMonthGoal.storeId, token))
    }
  }, [currentMonthGoal.storeId])

  // Se chegar os reports do mês, e não tiver nenhum intervalo pré selecionado, atualiza
  useEffect(() => {
    // apresenta reports do intervalo selecionado
    if (reportsList) {
      setReports(reportsList)
    } else setReports(monthReport)
    setInputsList(inputList)
  }, [monthReport, reportsList])

  useEffect(() => {
    if (currentMonthGoal && user) {
      const workingDays = currentMonthGoal.days
        .filter(day => day.working && day.users.includes(user.userId?._id))
        .map(day => new Date(day.date))

      setDays(
        user.active
          ? workingDays
          : currentMonthGoal.days.map(day => new Date(day.date))
      )

      const selectedUser = currentMonthGoal.usersWorking.find(
        userW => userW.userId?._id === user.userId?._id
      )

      setSeller(prev => ({
        ...prev,
        dsr:
          user.active && selectedUser
            ? selectedUser.dsr
            : currentMonthGoal.dsr > 0,
        goalWeight:
          (user.active && selectedUser ? selectedUser.goalWeight : 1) * 100
      }))
      setTab(selectedUser?.weightLocked ? 1 : 0)
    }
  }, [currentMonthGoal, user])

  useEffect(() => {
    if (currentMonthGoal && !user?.active) {
      setDays(
        currentMonthGoal.days
          .filter(day => day.working)
          .map(day => new Date(day.date))
      )
    }
  }, [currentMonthGoal, user])

  // CurrentMonthGoal filtrado
  const currMonth = (obj => ({
    days: obj.days,
    usersWorking: obj.usersWorking,
    updatedAt: obj.updatedAt,
    mainGoals: obj.mainGoals,
    monthGoals: obj.monthGoals,
    distribute: obj.distribute,
    activeUsers: obj.usersWorking?.filter(user => user.active)?.length
  }))(currentMonthGoal)

  // Essa função é utilizada para cada usuário da meta, sendo ele inativo ou não.
  // Quando o usuário for inativo essa função vai calcular somente o tanto que ele
  // tinha de meta até ele ficar inativo (ultimo dia de venda)
  // Quando for um usuário ativo (o do modal), ela vai calcular a meta distribuida
  // desse vendedor e aplicar o remanescente da meta do(s) vendedor(es) inativo(s)
  const goalSeller = (
    actualSeller: IUserWorking,
    uwSellersGoal?: IUwwSellerGoalProps[]
  ) => {
    // Os dias com a inclusão de uma nova propriedade que é o acumulo do peso dos
    // vendedores, resultando o peso do dia
    const daysWithWeight: IDaysWithWeight[] = currMonth?.days.map(day => ({
      ...day,
      dayWeight:
        day.users?.length > 0
          ? day.users.reduce(
              (acc, seller) =>
                (acc +=
                  currMonth.usersWorking.find(
                    user => user.userId?._id === seller
                  )?.goalWeight || 0),
              0
            )
          : 1 // Se não tiver nenhum vendedor, o peso do dia é 1
    }))

    // Quando abrir o modal preciso identificar os dias selecionados pelo usuario

    const currUserDays =
      (daysWithWeight.length > 0 &&
        daysWithWeight.reduce(
          (acc: IDaysWithWeight[], curr: IDaysWithWeight) => {
            if (
              days.length > 0 &&
              days.some(day => day.toISOString() === curr.date)
            ) {
              if (!curr.users.some(userDay => userDay === user?.userId._id)) {
                curr.dayWeight += Number(seller.goalWeight) / 100
              }
              acc.push(curr)
            }
            return acc
          },
          []
        )) ||
      []

    // Essa função tem dupla funcionalidade:
    // Vendedor Inativo => Ela calcula a meta remanescente dele até o ultimo dia de venda
    // Vendedor Ativo => É o calculo da meta para o vendedor ativo, conforme cada vendedor
    // inativo após a data de seus ultimos lançamentos.
    const addAccGoal = (lastInput?: string) => {
      const inactiveUser = currMonth.usersWorking.find(
        user => user.userId._id === actualSeller.userId._id
      )

      return (
        (daysWithWeight.length > 0 &&
          daysWithWeight.reduce((acc, day) => {
            acc +=
              ((!uwSellersGoal &&
                day.users.some(
                  seller => seller === actualSeller.userId?._id
                )) ||
                (uwSellersGoal &&
                  days.some(
                    modalDay => modalDay.toISOString() === day.date
                  ))) &&
              // Se o vendedor não tiver lastInput (ultimo dia de venda),
              // significa que é um vendedor inativo, então irá calcular sua
              //  meta remanescente a partir do ultimo dia de venda

              // Se tiver ultimo dia de venda e se a data do ultimo dia de venda
              // for menor que a data do dia atual, significa que o vendedor não
              // vendeu nada, então a meta remanescente é a meta do vendedor
              (!lastInput ||
                (lastInput && +new Date(day.date) > +new Date(lastInput)))
                ? day.goal *
                  (uwSellersGoal
                    ? 1
                    : inactiveUser
                    ? inactiveUser.goalWeight / day.dayWeight
                    : 0)
                : 0
            return acc
          }, 0)) ||
        0
      )
    }

    // Verificação é pra saber se a função ta sendo utilizada para o vendedor ativo ou inativo
    // Caso não tenha essa variavel (uwSellersGoal) é porque o vendedor ainda é um inativo.
    // uwSellersGoal => É a meta remanescente dos vendedores inativos
    if (uwSellersGoal) {
      const uwSellersGoalAfterExit =
        uwSellersGoal.length > 0
          ? uwSellersGoal.map(uwSeller => {
              uwSeller.accGoalAfterExit = addAccGoal(uwSeller?.lastInput)
              return uwSeller
            })
          : []

      // Calcula todas as metas com suas respectivas distribuições
      // (se for o modelo de meta distribuida) e também a distribuição dos
      // vendedores inativos (complemento)
      const daysGoalAcc = (
        mainGoal: IMonthGoal,
        currGoal: IMonthGoal,
        i = 0,
        recalculatedGoal = 0
      ): number => {
        if (currUserDays.length > 0) {
          const complementSum = (
            day: IDaysWithWeight,
            dayWeight: number,
            i: number
          ) =>
            uwSellersGoalAfterExit?.length > 0 &&
            (!uwSellersGoalAfterExit[i].lastInput ||
              day.date > uwSellersGoalAfterExit[i].lastInput)
              ? // (((Residual Inicial * (Metal indivudal atual / Meta individual principal))
                // - Venda total removido) * Novo peso do dia * Peso do vendedor no dia)
                (uwSellersGoalAfterExit[i].remainingGoal *
                  (currGoal.value / mainGoal.value) -
                  uwSellersGoalAfterExit[i].sold) *
                (day.goal / (uwSellersGoalAfterExit[i].accGoalAfterExit ?? 1)) *
                dayWeight
              : 0

          // Se não possuir usuário ativo, o peso será 1
          const dayWeight =
            currMonth.activeUsers === 0
              ? 1
              : Number(seller.goalWeight) / 100 / currMonth.activeUsers

          if (i === 0) {
            recalculatedGoal += currUserDays.reduce((acc, day) => {
              acc += currMonth.distribute
                ? day.goal *
                    (currGoal.value / mainGoal.value) *
                    (Number(seller.goalWeight) / 100 / day.dayWeight) +
                  complementSum(day, dayWeight, i)
                : ((currGoal.value * day.goal) / currMonth.mainGoals.total) *
                  (Number(seller.goalWeight) / 100)

              return acc
            }, 0)
          } else {
            recalculatedGoal += currUserDays.reduce(
              (acc, day) => (acc += complementSum(day, dayWeight, i)),
              0
            )
          }

          return uwSellersGoalAfterExit?.length > 0 &&
            uwSellersGoalAfterExit.length - 1 !== i
            ? daysGoalAcc(mainGoal, currGoal, i + 1, recalculatedGoal)
            : recalculatedGoal
        }
        return 0
      }

      return currMonth.monthGoals.map(monthGoal => ({
        ...monthGoal,
        value: daysGoalAcc(currMonth.monthGoals[0], monthGoal)
      }))
    }
    // Meta acumulada do inativo
    return addAccGoal()
  }

  const getMonthGoals = () => {
    if (user && currMonth?.monthGoals?.length > 0) {
      const sellerInactiveWithSold = currMonth.distribute
        ? currMonth.usersWorking // Ordena os vendedores por ativo ou inativo
            .reduce((acc: IUwwSellerGoalProps[], curr: IUserWorking) => {
              const isModalUser = curr.userId?._id === user.userId?._id
              if (!curr.active && !isModalUser) {
                let sellerSold = 0

                const inputsFromUwSeller = inputsList?.filter(input => {
                  // Conferir se estão por ordem decrescente de data
                  const actualMonth =
                    moment(input.date).month() ===
                    moment(currentMonthGoal.month).month()

                  // Vendedor aberto no modal
                  const currSeller = input.sellers.find(
                    seller =>
                      seller.sellerId?._id === curr.userId?._id &&
                      seller.sold > 0
                  )

                  if (actualMonth && currSeller) {
                    sellerSold += currSeller.sold
                    return input
                  }
                })

                const remainingGoal = goalSeller(curr)

                // Se a venda for menor do que o remanescente, podemos abater o valor,
                // caso contrário não irá retornar uma remaingGoal
                if (
                  typeof remainingGoal === 'number' &&
                  sellerSold < remainingGoal
                ) {
                  acc.push({
                    remainingGoal,
                    sold: sellerSold,
                    lastInput: inputsFromUwSeller[0]?.date
                  })
                }
              }
              return acc
            }, [])
        : []
      const result = goalSeller(user, sellerInactiveWithSold)

      if (result) {
        setAccGoal(result)
        return result
      }
    }
  }

  useEffect(() => {
    if (user && currMonth) getMonthGoals()
  }, [modal, days, seller, user])

  const remainWeight = currentMonthGoal?.usersWorking.reduce(
    (acc, currUser, i) => {
      if (currUser.active) {
        acc.weightToDistribute += 1
        if (
          currUser.weightLocked &&
          !(currUser?.userId?._id === user?.userId?._id)
        ) {
          acc.weightToDistribute -= currUser.goalWeight
        } else {
          acc.usersToDistribute += 1
        }
      }
      if (i === currentMonthGoal.usersWorking.length - 1) {
        if (!user?.active) {
          acc.weightToDistribute += 1
          acc.usersToDistribute += 1
        }
        const weightDistributed =
          (acc.weightToDistribute / acc.usersToDistribute) * 100
        acc.weightToDistribute = weightDistributed < 0 ? 0 : weightDistributed
      }
      return acc
    },
    { weightToDistribute: 0, usersToDistribute: 0 }
  )

  return (
    <Container className={classes.root}>
      {user && (
        <Dialog open={modal} onClose={onClose}>
          <DialogTitle id='form-dialog-title'>
            Editar {user?.userId?.name.complete || '...'}
          </DialogTitle>
          <DialogContent>
            <Typography>
              Defina o peso e os dias que
              <strong> {user?.userId?.name.first || '...'}</strong> irá
              trabalhar. <br />A meta do vendedor será proporcional a esses
              parâmetros.
            </Typography>
            {user && currentMonthGoal ? (
              <div style={{ marginTop: 20 }}>
                <Grid container className={classes.margin}>
                  <div
                    style={{
                      borderRight: '2px solid #EEEEEE',
                      paddingRight: 20
                    }}
                  >
                    <Picker>
                      <DayPicker
                        month={new Date(currentMonthGoal.month)}
                        canChangeMonth={false}
                        months={dayPickerBR.months}
                        weekdaysLong={dayPickerBR.weekdaysLong}
                        weekdaysShort={dayPickerBR.weekdaysShort}
                        firstDayOfWeek={0}
                        labels={dayPickerBR.labels}
                        modifiers={{
                          working: day => days.includes(day)
                        }}
                        selectedDays={days}
                        modifiersStyles={{
                          'DayPicker-Caption': {
                            fontFamily: 'Roboto'
                          },
                          working: {
                            color: '#000',
                            fontWeight: 'bold',
                            fontFamily: 'Roboto'
                          }
                        }}
                        onDayClick={(day, { selected }) => {
                          if (
                            currentMonthGoal.days
                              .filter(d => d.working)
                              .map(dayM => new Date(dayM.date).getUTCDate())
                              .includes(day.getUTCDate())
                          ) {
                            if (selected) {
                              // desselecionar
                              const newDays = days.filter(
                                eachDay => !DateUtils.isSameDay(eachDay, day)
                              )
                              setDays(newDays)
                            } else {
                              setDays([...days, day])
                            }
                          }
                        }}
                      />
                    </Picker>
                    <Button
                      style={{ display: 'flex', marginLeft: 'auto' }}
                      onClick={() =>
                        setDays(
                          currentMonthGoal.days
                            .filter(day => day.working)
                            .map(day => new Date(day.date))
                        )
                      }
                    >
                      <svg
                        style={{ marginRight: 4 }}
                        width='18'
                        height='11'
                        viewBox='0 0 18 11'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M13.5 1.25L12.4425 0.192505L7.6875 4.9475L8.745 6.005L13.5 1.25ZM16.68 0.192505L8.745 8.1275L5.61 5L4.5525 6.0575L8.745 10.25L17.745 1.25L16.68 0.192505ZM0.307495 6.0575L4.5 10.25L5.5575 9.1925L1.3725 5L0.307495 6.0575Z'
                          fill='#37474F'
                        />
                      </svg>
                      Todos os dias
                    </Button>
                  </div>
                  <Grid style={{ paddingLeft: 20 }} item xs>
                    <Item>
                      <ItemTitle>Vendedor recebe DSR?</ItemTitle>
                      <DSRCheckbox
                        onChange={(_, checked) => {
                          setSeller({ ...seller, dsr: checked })
                        }}
                        label={seller.dsr ? 'Receberá' : 'Não receberá'}
                        dsr={String(seller.dsr)}
                        control={
                          <Checkbox
                            disableRipple
                            color='primary'
                            checked={seller.dsr}
                            icon={<RadioButtonUnchecked />}
                            checkedIcon={<CheckCircle color='primary' />}
                          />
                        }
                      />
                    </Item>
                    <Item>
                      <ItemTitle>Peso do vendedor</ItemTitle>
                      <WeightSelection>
                        <Tooltip
                          fixed
                          id='weight-selection-left'
                          content={
                            <p>
                              Peso ajustado automaticamente em referência aos
                              outros vendedores
                            </p>
                          }
                        />
                        <WeightSelectionItem
                          data-tip
                          data-for='weight-selection-left'
                          onClick={() => {
                            setTab(0)
                            setSeller(state => ({
                              ...state,
                              edited: true,
                              weightLocked: false,
                              goalWeight: currentMonthGoal.distribute
                                ? remainWeight.weightToDistribute.toFixed(2)
                                : 100
                            }))
                          }}
                          selected={tab === 0}
                        >
                          <SpeedIcon fontSize='small' />
                          <span>Auto</span>
                        </WeightSelectionItem>

                        <Tooltip
                          fixed
                          id='weight-selection-right'
                          content={
                            <p>
                              O peso do vendedor é fixado no valor inserido
                              abaixo
                            </p>
                          }
                        />
                        <WeightSelectionItem
                          data-tip
                          data-for='weight-selection-right'
                          onClick={() => {
                            setSeller(state => ({
                              ...state,
                              goalWeight: user.goalWeight * 100,
                              edited: true,
                              weightLocked: true
                            }))
                            setTab(1)
                          }}
                          right
                          selected={tab === 1}
                        >
                          <Lock fontSize='small' />
                          <span>Fixado</span>
                        </WeightSelectionItem>
                      </WeightSelection>
                      <InputContainer>
                        <input
                          maxLength={7}
                          value={
                            !user.active && !seller.edited
                              ? remainWeight.weightToDistribute.toFixed(3)
                              : typeof seller.goalWeight === 'number'
                              ? seller.goalWeight.toFixed(3)
                              : seller.goalWeight
                          }
                          style={{ color: tab === 0 ? '#FFC700' : '#000' }}
                          pattern='[0-9]*'
                          onChange={e => {
                            const { value } = e.target
                            setSeller(state => ({
                              ...state,
                              edited: true,
                              weightLocked: true,
                              goalWeight:
                                Number(value) > 300
                                  ? 300
                                  : value.replace(',', '.')
                            }))
                            setTab(1)
                          }}
                          data-clarity-unmask='true'
                        />
                        <label>%</label>
                      </InputContainer>

                      <SliderContainer>
                        <span>0%</span>
                        <Slider
                          className='slider'
                          defaultValue={100}
                          step={5}
                          min={0}
                          max={300}
                          onChange={(e, value) => {
                            setSeller(state => ({
                              ...state,
                              goalWeight: value as number,
                              edited: true,
                              weightLocked: true
                            }))
                            setTab(1)
                          }}
                          value={Math.round(
                            !user.active && !seller.edited
                              ? Number(
                                  remainWeight.weightToDistribute.toFixed(2)
                                )
                              : Number(seller.goalWeight)
                          )}
                          sx={{
                            '& .MuiSlider-track': {
                              color: tabColorOptions,
                              transition: '0.2s ease-in'
                            },
                            '& .MuiSlider-thumb': {
                              borderColor: tabColorOptions,
                              transition: '0.2s ease-in'
                            },
                            '& .MuiSlider-mark': {
                              backgroundColor: tabColorOptions,
                              transition: '0.2s ease-in'
                            }
                          }}
                          marks={[
                            { value: 0 },
                            { value: 100 },
                            { value: 200 },
                            { value: 300 }
                          ]}
                        />
                        <span>300%</span>
                      </SliderContainer>
                    </Item>
                    <Item>
                      <ItemTitle>Meta individual / Remuneração</ItemTitle>
                      {currMonth &&
                        days &&
                        Array.isArray(accGoal) &&
                        accGoal.length > 0 &&
                        user &&
                        accGoal.map((goal, index) => {
                          const preCommission = goal.value * goal.commission

                          const bonus = goal.bonusPercentage
                            ? goal.value * goal.bonus
                            : goal.bonus

                          const dsr =
                            currentMonthGoal.dsr > 0 && seller.dsr
                              ? (preCommission /
                                  (currentMonthGoal.days.length -
                                    currentMonthGoal.dsr)) *
                                currentMonthGoal.dsr
                              : 0

                          const base = currentMonthGoal.salary.directCommission
                            ? 0
                            : user.salary ?? currentMonthGoal.salary.base

                          const preComplement =
                            (user.salary || currentMonthGoal.salary.base) -
                            preCommission -
                            dsr

                          const complement =
                            currentMonthGoal.salary.directCommission &&
                            currentMonthGoal.salary.complement &&
                            preComplement >= 0
                              ? preComplement
                              : 0

                          const remuneration =
                            preCommission + dsr + bonus + base + complement

                          const userGoalTooltipInfos = {
                            ...(preCommission && {
                              Comissão: formatPrice(preCommission)
                            }),
                            ...(dsr && { DSR: formatPrice(dsr) }),
                            ...(base && { Piso: formatPrice(base) }),
                            ...(complement && {
                              Complemento: formatPrice(complement)
                            }),
                            ...(bonus && { Bônus: formatPrice(bonus) })
                          }

                          return (
                            <GoalCard key={goal.name}>
                              <h1>{goal.name}</h1>
                              <h2>
                                <strong
                                  {...(index === 0 && {
                                    'data-cy': 'mainGoalValueGoalUserModal'
                                  })}
                                >
                                  {formatPrice(goal.value < 0 ? 0 : goal.value)}
                                </strong>{' '}
                                <span
                                  {...(index === 0 && {
                                    'data-cy':
                                      'mainCommissionValueGoalUserModal'
                                  })}
                                >
                                  /{' '}
                                  <Tooltip
                                    id={`user-goal-commission-${goal._id}`}
                                    content={
                                      <div>
                                        <strong
                                          style={{
                                            display: 'block',
                                            marginBottom: '10px',
                                            fontSize: '14px'
                                          }}
                                        >
                                          Remuneração Total <br />
                                        </strong>
                                        {Object.entries(
                                          userGoalTooltipInfos
                                        ).map(([key, value]) => (
                                          <p
                                            key={key}
                                            style={{ marginBottom: '2px' }}
                                          >
                                            <strong>{key}: </strong> {value}
                                            <br />
                                          </p>
                                        ))}
                                        <p style={{ marginTop: '10px' }}>
                                          <strong>Total: </strong>
                                          {formatPrice(remuneration)}
                                        </p>
                                      </div>
                                    }
                                    place='bottom'
                                  />
                                  {formatPrice(
                                    remuneration < 0 ? 0 : remuneration
                                  )}
                                  <ErrorOutlineIcon
                                    data-tip
                                    data-for={`user-goal-commission-${goal._id}`}
                                    style={{
                                      color: '#C4C4C4',
                                      fontSize: 20,
                                      marginLeft: 5,
                                      marginBottom: -5
                                    }}
                                  />
                                </span>
                              </h2>
                            </GoalCard>
                          )
                        })}
                    </Item>
                  </Grid>
                </Grid>
              </div>
            ) : (
              <DialogContentText>Carregando...</DialogContentText>
            )}
          </DialogContent>
          <ActionButtons>
            {user?.active &&
              (usersWorkingLength > 1 ? (
                <Button
                  onClick={() => {
                    confirm({
                      title: 'Remover vendedor da meta',
                      description: `Você tem certeza que deseja remover ${user.userId.name.first} da meta?`,
                      confirmationText: 'Sim',
                      cancellationText: 'Não'
                    })
                      .then(() => {
                        handleRemoveUser(user)
                      })
                      .catch(() => {})
                  }}
                  style={{
                    color: '#e74c3c'
                  }}
                >
                  <PersonRemoveIcon style={{ marginRight: 4 }} />
                  Remover da meta
                </Button>
              ) : (
                <BootstrapTooltip title='A meta deve ter pelo menos um vendedor'>
                  <Button
                    style={{
                      color: '#e74c3c',
                      cursor: 'not-allowed',
                      opacity: 0.6
                    }}
                  >
                    <PersonRemoveIcon style={{ marginRight: 4 }} />
                    Remover da meta
                  </Button>
                </BootstrapTooltip>
              ))}
            <div style={{ marginLeft: 'auto' }}>
              <Button
                data-cy='cancelGoalUserModalButton'
                style={{ margin: '0 5px' }}
                onClick={onClose}
              >
                Cancelar
              </Button>
              <Button
                variant='contained'
                color='primary'
                disabled={days?.length === 0}
                onClick={() => {
                  confirm({
                    title: 'Salvar',
                    description: `Atenção: A meta de ${user.userId.name.first} será proporcional aos dias trabalhados.`,
                    confirmationText: 'Ok',
                    cancellationText: 'Cancelar'
                  })
                    .then(() => {
                      handleUpdateUserWeight(
                        days.map(d => d.toISOString()),
                        user,
                        tab === 1 ? 'fixed' : 'auto',
                        Number(seller.goalWeight) / 100,
                        currentMonthGoal._id,
                        false
                      )
                      onClose()
                    })
                    .catch(() => {})
                }}
                type='submit'
              >
                {loading && <CircularProgress size={24} />}
                {user?.active ? 'Salvar' : 'Adicionar à meta'}
              </Button>
            </div>
          </ActionButtons>
        </Dialog>
      )}
    </Container>
  )
}
