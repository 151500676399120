import { useState } from 'react'

import * as XLSX from 'xlsx'
import { lastDayOfMonth, startOfMonth, subMonths } from 'date-fns'

// Material UI
import {
  ListItemText,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core'

import { CloseTwoTone, CloudDownloadTwoTone } from '@material-ui/icons'

import { SheetButton, SheetModal } from 'views/Reports/components/styles'
import api from 'repositories/api'
import { useSelector } from 'react-redux'
import { safeDivision } from 'utils/format'
import { CircularProgress } from '@mui/material'
import { toast } from 'react-toastify'
import { deepClone } from 'utils/deepClone'

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 300,
      width: 150
    }
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left'
  },
  getContentAnchorEl: null
}

export const ExportSpreadSheetModal = ({ isOpen, onClose }) => {
  const { user } = useSelector(state => state.user)

  const [selectedMonth, setSelectedMonth] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const currentMonth = new Date().setHours(0, 0, 0, 0)

  const months = [
    new Date(currentMonth).toISOString(),
    subMonths(currentMonth, 1).toISOString(),
    subMonths(currentMonth, 2).toISOString()
  ]

  const getMonthName = stringDate => {
    const dateMonth = new Date(stringDate).toLocaleDateString('pt-BR', {
      month: 'long'
    })

    return `${dateMonth[0].toUpperCase()}${dateMonth.slice(1)}`
  }

  const closeModal = () => {
    setSelectedMonth('')
    onClose()
  }

  const handleExportAllSpreadSheet = async () => {
    if (!selectedMonth) {
      toast.error('Selecione o mês que quer exportar')
    } else {
      try {
        setIsLoading(true)
        const startMonth = startOfMonth(
          new Date(selectedMonth).setHours(0, 0, 0, 0)
        ).toISOString()
        const endMonth = lastDayOfMonth(new Date(selectedMonth)).toISOString()
        const monthName = getMonthName(selectedMonth)

        const response = await api.axios.get(
          `report/user/${user._id}/export?from=${startMonth}&to=${endMonth}`,
          {
            timeout: 120000
          }
        )

        const formatTableStoreDataToExcel = storeToBeFormatted => [
          'Loja',
          storeToBeFormatted.total.sold ?? 0, // Faturamento
          storeToBeFormatted.metric.acDayGoal ?? 0, // Meta Ideal
          safeDivision(
            storeToBeFormatted.total.sold / storeToBeFormatted.metric.acDayGoal
          ), // Porcentagem da meta
          '-', // Meta Atual
          storeToBeFormatted.projection.sold ?? 0, // Projeção de Venda
          '-', // Comissão
          '-', // Projeção de Comissão
          storeToBeFormatted.total.sales ?? 0, // N˚ Vendas
          safeDivision(
            storeToBeFormatted.total.sold / storeToBeFormatted.total.sales
          ), // Ticket Médio
          safeDivision(
            storeToBeFormatted.total.items / storeToBeFormatted.total.sales
          ), // Peça/Venda
          safeDivision(
            storeToBeFormatted.total.sold / storeToBeFormatted.total.items
          ), // Preço Médio
          storeToBeFormatted.total.items ?? 0 // N˚ Peças
        ]

        const formatTableSellersDataToExcel = sellerToBeFormatted => [
          sellerToBeFormatted.active
            ? sellerToBeFormatted.name.complete
            : `(INATIVO) ${sellerToBeFormatted.name.complete}`, // Nome do vendedor
          sellerToBeFormatted.total.sold ?? 0, // Faturamento do vendedor
          sellerToBeFormatted.metric.acDayGoal ?? 0, // Meta Ideal do vendedor
          safeDivision(
            sellerToBeFormatted.total.sold /
              sellerToBeFormatted.metric.acDayGoal
          ), // % da Meta
          sellerToBeFormatted.projection.goal.name || '-', // Meta atual
          sellerToBeFormatted.projection.sold ?? 0, // Projeção de Venda
          sellerToBeFormatted.commission ?? 0, // Comissão
          sellerToBeFormatted.projection.commission || '-', // Projeção de Comissão
          sellerToBeFormatted.total.sales ?? 0, // N˚ Vendas
          safeDivision(
            sellerToBeFormatted.total.sold / sellerToBeFormatted.total.sales
          ), // Ticket Médio
          safeDivision(
            sellerToBeFormatted.total.items / sellerToBeFormatted.total.sales
          ), // Peça/Venda
          safeDivision(
            sellerToBeFormatted.total.sold / sellerToBeFormatted.total.items
          ), // Preço Médio
          sellerToBeFormatted.total.items ?? 0 // N˚ Peças
        ]

        const tableHeaders = [
          'Nome',
          'Faturamento',
          'Meta Ideal',
          '% da Meta',
          'Meta Atual',
          'Projeção de Venda',
          'Comissão',
          'Projeção de Comissão',
          'N˚ Vendas',
          'Ticket Médio',
          'Peça/Venda',
          'Preço Médio',
          'N˚ Peças'
        ]

        const wb = XLSX.utils.book_new()

        const reservedNames = []
        const allStoresTabSheet = []

        let sellersCount = 0
        let storesCount = 0
        let indexCount = 0

        response.data?.forEach(
          ({ name, store, sellers, otherInputs }, index) => {
            // Caso seja loja Oscar
            let oscarTableStoreData = []
            let oscarTableUsersData = []
            let sellersOtherInputsCount = 0

            let formattedName = name

            if (reservedNames.includes(name)) {
              formattedName = name.substring(0, 31 - 16) + '(nome duplicado)'
            } else if (
              name.length > 31 &&
              reservedNames.find(
                reservedName =>
                  reservedName.substring(0, 31 - 3) + '...' ===
                  name.substring(0, 31 - 3) + '...'
              )
            ) {
              const subNumber = (indexCount + 1).toString().length
              formattedName =
                name.substring(0, 31 - 5 - subNumber) + `...(${indexCount + 1})`
              indexCount += 1
            } else {
              if (name.length > 31) {
                formattedName = name.substring(0, 31 - 3) + '...'
              }
            }

            reservedNames.push(name)

            if (store) {
              sellersCount += sellers.length
              storesCount++

              const tableStoreData = formatTableStoreDataToExcel(store)

              // Tabela para cada loja das Lojas Oscar
              if (otherInputs) {
                const total = {
                  items: otherInputs.other.items + store.total.items,
                  sales: otherInputs.other.sales + store.total.sales,
                  sold: otherInputs.other.sold + store.total.sold
                }

                const formattedOscarStoreTable = formatTableStoreDataToExcel({
                  ...otherInputs,
                  projection: { sold: otherInputs.store.soldProjection },
                  metric: { acDayGoal: store.metric.acDayGoal },
                  total
                })

                // Remoção de alguns dados das colunas da loja das Lojas Oscar
                const indexColumnsToBeModified = [3, 4, 6, 7]

                for (let i = 0; i < indexColumnsToBeModified.length; i++) {
                  formattedOscarStoreTable.splice(
                    indexColumnsToBeModified[i],
                    1,
                    '-'
                  )
                }
                oscarTableStoreData = formattedOscarStoreTable
              }

              const tableUsersData = sellers.map(seller => {
                // Tabela para cada vendedor das Lojas Oscar
                if (seller.otherInputs) {
                  const formattedSellerOscarStoreTable =
                    formatTableSellersDataToExcel({
                      ...seller,
                      total: { ...seller.otherInputs }
                    })

                  // Remoção de alguns dados das colunas de vendedores das Lojas Oscar
                  const indexColumnsToBeModified = [4, 6, 7]

                  for (let i = 0; i < indexColumnsToBeModified.length; i++) {
                    formattedSellerOscarStoreTable.splice(
                      indexColumnsToBeModified[i],
                      1,
                      '-'
                    )
                  }

                  oscarTableUsersData.push(formattedSellerOscarStoreTable)
                  sellersOtherInputsCount++
                }

                // Tabela de lojas comuns
                return formatTableSellersDataToExcel(seller)
              })

              const defaultColumnsExcel = [
                [name],
                [monthName.toUpperCase()],
                tableHeaders,
                tableStoreData,
                ...tableUsersData
              ]

              // Adiciona nome da loja nas colunas loja e vendedores (na tab Todas as lojas)
              const allStoresTableUserData = deepClone(tableUsersData)
              allStoresTableUserData.forEach(userRow =>
                userRow.splice(1, 0, name)
              )

              const allStoresTableStoreData = deepClone(tableStoreData)
              allStoresTableStoreData.splice(1, 0, name)

              const allStoresColumn = [
                allStoresTableStoreData,
                ...allStoresTableUserData
              ]

              // Se for a primeira iteração, adiciona o header da planilha na tab 'Todas as lojas'
              if (index === 0) {
                const allStoresTableHeaders = [...tableHeaders]
                allStoresTableHeaders.splice(1, 0, 'Loja')
                allStoresColumn.unshift(allStoresTableHeaders)
              }

              if (otherInputs) {
                defaultColumnsExcel.splice(2, 0, [
                  'Desempenho vendedores - Todas as Lojas'
                ])

                defaultColumnsExcel.push(
                  [],
                  [`Desempenho Loja - ${name}`],
                  tableHeaders,
                  oscarTableStoreData,
                  ...oscarTableUsersData
                )
              }

              allStoresTabSheet.push(allStoresColumn)

              const ws = XLSX.utils.aoa_to_sheet(defaultColumnsExcel)

              const indexSum = otherInputs ? 5 : 4
              // Lojas normais
              new Array(sellers.length + 1).fill(0).forEach((_, index) => {
                ws[`B${index + indexSum}`].z = '"R$ "#,##0.00' // Faturamento
                ws[`C${index + indexSum}`].z = '"R$ "#,##0.00' // Meta ideal
                ws[`D${index + indexSum}`].z = '0.00%' // % da Meta
                ws[`F${index + indexSum}`].z = '"R$ "#,##0.00' // Projeção de venda
                ws[`G${index + indexSum}`].z = '"R$ "#,##0.00' // Comissão
                ws[`H${index + indexSum}`].z = '"R$ "#,##0.00' // Projeção comissão
                ws[`I${index + indexSum}`].z = '0' // Nº de vendas
                ws[`J${index + indexSum}`].z = '"R$ "#,##0.00' // Ticket médio
                ws[`K${index + indexSum}`].z = '0.00' // Peça/Venda
                ws[`L${index + indexSum}`].z = '"R$ "#,##0.00' // Preço médio
                ws[`M${index + indexSum}`].z = '0' // Nº de Peças
              })

              if (otherInputs) {
                new Array(sellersOtherInputsCount + 1)
                  .fill(0)
                  .forEach((_, index) => {
                    // other inputs
                    ws[`B${index + 9 + sellers.length}`].z = '"R$ "#,##0.00' // Faturamento
                    ws[`C${index + 9 + sellers.length}`].z = '"R$ "#,##0.00' // Meta ideal
                    ws[`D${index + 9 + sellers.length}`].z = '0.00%' // % da Meta
                    ws[`F${index + 9 + sellers.length}`].z = '"R$ "#,##0.00' // Projeção de venda
                    ws[`G${index + 9 + sellers.length}`].z = '"R$ "#,##0.00' // Comissão
                    ws[`H${index + 9 + sellers.length}`].z = '"R$ "#,##0.00' // Projeção comissão
                    ws[`I${index + 9 + sellers.length}`].z = '0' // Nº de vendas
                    ws[`J${index + 9 + sellers.length}`].z = '"R$ "#,##0.00' // Ticket médio
                    ws[`K${index + 9 + sellers.length}`].z = '0.00' // Peça/Venda
                    ws[`L${index + 9 + sellers.length}`].z = '"R$ "#,##0.00' // Preço médio
                    ws[`M${index + 9 + sellers.length}`].z = '0' // Nº de Peças
                  })
              }

              XLSX.utils.book_append_sheet(wb, ws, formattedName)
            } else {
              const ws = XLSX.utils.aoa_to_sheet([
                [name.toUpperCase()],
                [monthName.toUpperCase()],
                ['Sem meta cadastrada para esse mês.']
              ])
              XLSX.utils.book_append_sheet(wb, ws, formattedName)
            }
          }
        )

        const ws = XLSX.utils.aoa_to_sheet([...allStoresTabSheet.flat(1)])

        new Array(sellersCount + storesCount + 1)
          .fill(0)
          .forEach((_, index) => {
            ws[`C${index + 1}`].z = '"R$ "#,##0.00' // Faturamento
            ws[`D${index + 1}`].z = '"R$ "#,##0.00' // Meta ideal
            ws[`E${index + 1}`].z = '0.00%' // % da Meta
            ws[`G${index + 1}`].z = '"R$ "#,##0.00' // Projeção de venda
            ws[`H${index + 1}`].z = '"R$ "#,##0.00' // Comissão
            ws[`I${index + 1}`].z = '"R$ "#,##0.00' // Projeção comissão
            ws[`J${index + 1}`].z = '0' // Nº de vendas
            ws[`K${index + 1}`].z = '"R$ "#,##0.00' // Ticket médio
            ws[`L${index + 1}`].z = '0.00' // Peça/Venda
            ws[`M${index + 1}`].z = '"R$ "#,##0.00' // Preço médio
            ws[`N${index + 1}`].z = '0' // Nº de Peças
          })

        XLSX.utils.book_append_sheet(wb, ws, 'Todas as lojas')

        // Removemos a tab 'Todas as lojas' que está no final pra poder adicionar no inicio das tabs
        const allStoresColumnIndex = wb.SheetNames.indexOf('Todas as lojas')
        wb.SheetNames.splice(allStoresColumnIndex, 1)
        wb.SheetNames.unshift('Todas as lojas')

        XLSX.writeFile(wb, `[NEOPRO] Relatorio-Lojas-${monthName}.xlsx`, {
          bookType: 'xlsx',
          type: 'binary'
        })
        setIsLoading(false)
      } catch (err) {
        console.log(err)
        toast.error('Houve um erro ao tentar gerar a planilha')
        setIsLoading(false)
      }
    }
  }

  return (
    <SheetModal open={isOpen} onClose={closeModal}>
      <div className='content'>
        <h1>Qual mês você deseja exportar?</h1>
        <span>Selecione abaixo o mês desejado</span>

        <div className='close-button' onClick={closeModal}>
          <CloseTwoTone style={{ color: 'lightgrey' }} />
        </div>

        <div className='checkbox-container'>
          <FormControl>
            <InputLabel>Mês</InputLabel>
            <Select
              value={selectedMonth}
              onChange={event => setSelectedMonth(`${event.target.value}`)}
              MenuProps={MenuProps}
              style={{
                width: '150px'
              }}
            >
              {months.map(month => {
                const monthName = getMonthName(month)
                return (
                  <MenuItem key={month} value={month}>
                    <ListItemText primary={monthName} />
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </div>

        <SheetButton
          disabled={isLoading}
          onClick={handleExportAllSpreadSheet}
          isModalButton
        >
          {isLoading ? (
            <>
              <CircularProgress
                size={24}
                color='inherit'
                style={{ marginRight: 12 }}
              />
              Baixando....
            </>
          ) : (
            <>
              <CloudDownloadTwoTone style={{ marginRight: 12 }} />
              Fazer download da planilha
            </>
          )}
        </SheetButton>
      </div>
    </SheetModal>
  )
}
