import { useEffect } from 'react'

import ReactToolTip from 'react-tooltip'

import { Container } from './styles'

interface TooltipProps {
  id: string
  disabledValues?: boolean
  content: JSX.Element
  place?: 'top' | 'right' | 'bottom' | 'left'
  fixed?: boolean
}

const Tooltip = ({
  id,
  disabledValues = false,
  content,
  place = 'bottom',
  fixed = false
}: TooltipProps) => {
  useEffect(() => {
    if (!disabledValues) {
      ReactToolTip.rebuild()
    }
  }, [disabledValues])
  return (
    <Container>
      <ReactToolTip
        className='tooltip-container'
        id={id}
        place={place} // top, bottom, left, right
        effect={fixed ? 'solid' : 'float'}
      >
        {content}
      </ReactToolTip>
    </Container>
  )
}

export default Tooltip
